import VuexPersistence from 'vuex-persist'
import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'

// LogRocket Vuex Plugin to track mutation.
export const logrocketPlugin = createPlugin(LogRocket)

const saveStatePerUser = (key, state, storage) => {
  const userName = btoa(localStorage.getItem('chg_user') || 'default')
  const output = {
    ...JSON.parse(storage.getItem(key)),
    [userName]: state,
  }
  storage.setItem(key, JSON.stringify(output))
}

const loggedErrors = new Set()
const restoreStatePerUser = (key, storage) => {
  let output = {}
  const user = localStorage.getItem('chg_user')

  if (!user) return {}

  try {
    const persistedState = JSON.parse(storage.getItem(key))
    const userName = btoa(user)
    output = {
      ...persistedState?.[userName],
    }
  } catch (e) {
    if (!loggedErrors.has(e.message)) {
      LogRocket.captureException(e)
      loggedErrors.add(e.message)
    }
  }
  return output
}

/**
 * Session Store
 *
 * The contents of this store are cleared on login.
 *
 * *** IMPORTANT! ***
 *
 * Do not save the Prefill object! It contains the user's Social Security Number
 * and we don't want to save that to local storage!!!
 */
export const vuexSession = new VuexPersistence({
  key: 'vuex-session',
  storage: window.sessionStorage,

  saveState: saveStatePerUser,

  restoreState: restoreStatePerUser,

  reducer: s => {
    return {
      navigation: {
        activeActionPaneTab: s.navigation.activeActionPaneTab,
      },
      documents: {
        cvPromptDismissed: s.documents.cvPromptDismissed,
      },
    }
  },
})

/**
* Persistent Store
*
* The contents of this store persist between user sessions, but separate data by user email
* in case we don't want share data between the user when they use same computer.
*/
export const vuexProviderSpecificStorage = new VuexPersistence({
  key: 'vuex-local-provider-specific',
  storage: window.localStorage,

  saveState: saveStatePerUser,

  restoreState: restoreStatePerUser,
  filter: (mutation) => ['jobs/SET_JOB_DETAILS_VIEWED', 'documents/ADD_DOCUMENT_TO_VIEWED_LIST'].includes(mutation.type),
  reducer: s => {
    return {
      documents: {
        myDocumentsViewedList: s?.documents?.myDocumentsViewedList || [],
      },
      jobs: {
        jobDetailsViewedArray: s?.jobs?.jobDetailsViewedArray || [],
      },
    }
  },
})
/**
 * Persistent Store
 *
 * The contents of this store persist between user sessions.
 */
export const vuexLocalStorage = new VuexPersistence({
  key: 'vuex-local-storage',
  storage: window.localStorage,

  reducer: s => {
    const payload = {
      application: s.application,
      account: s.account ? { ...s.account, pendingRequestInFlight: false } : undefined,
      analytics: { ...s.analytics, webTracking: [] },
      correlationCookie: s.correlationCookie,
      correlationSession: s.correlationSession,
      identity: s.identity,
      locale: s.locale,
      locales: s.locales,
      navigation: {
        primary: {
          isOpen: s.navigation.primaryIsOpen,
        },
        modals: {
          lockedApplicationModalOpen: s.navigation.modals.lockedApplicationModalOpen,
          lockedTimeEntryModalOpen: s.navigation.modals.lockedTimeEntryModalOpen,
          availableToWorkModalOpen: false,
          sendToRepModalOpen: false,
          twoStepVerificationModalOpen: s.navigation.modals.twoStepVerificationModalOpen,
        },
        standalone: s.navigation.standalone,
        leftNavExpanded: s.navigation.leftNavExpanded,
        collapseIconDisplay: s.navigation.collapseIconDisplay,
      },
      banners: s.banners,
    }

    return payload
  },
})

export const strict = false

export const plugins = [
  logrocketPlugin,
  vuexSession.plugin,
  vuexLocalStorage.plugin,
  vuexProviderSpecificStorage.plugin,
]

// ──── STATE ─────────────────────────────────────────────────────────────────────────────
export const state = () => ({
  correlationCookie: '',
  correlationSession: '',
  locale: '',
  locales: ['en'],
})

// ──── MUTATIONS ─────────────────────────────────────────────────────────────────────────
export const mutations = {
  SET_CORRELATION_COOKIE(s, correlationCookie) {
    s.correlationCookie = s.correlationCookie || correlationCookie
  },

  SET_CORRELATION_SESSION(s, correlationSession) {
    s.correlationSession = s.correlationSession || correlationSession
  },

  SET_LANG(s, locale) {
    if (s.locales.indexOf(locale) !== -1) {
      s.locale = locale
    }
  },
}

// ──── GETTERS ───────────────────────────────────────────────────────────────────────────
export const getters = {
  sessionId: ({ correlationSession }) => correlationSession,
}

export const actions = {
  resetSessionStores({ commit }) {
    commit('navigation/RESET_STATE', null, { root: true })
  },
}
