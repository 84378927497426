import { gql } from '@apollo/client/core'

export const shiftsByAssignments = gql`
  query shiftsByAssignments($assignments: [ID!], $orderBy: [Order]) {
    shiftsByAssignments(assignments: $assignments, orderBy: $orderBy) {
      id
      assignmentId
      startDate
      endDate
      startTime
      endTime
      status
      type
      createdAt
      updatedAt
      worksites {
        worksiteId
        label
        description
      }
    }
  }
`

export default {
  shiftsByAssignments,
}
