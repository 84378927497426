import clone from 'lodash.clonedeep'
const getRedactedValue = v => '*********'

/**
 * A function used to remove sensitive values from a object.
 * @param {Object|String} value Object or String to be scrubbed
 * @param {Array<String>} sensitiveStrings Strings that will determine if a keys value should be scrubbed
 * This function will also scrub strings that have these keywords, unless those keys are whitelisted
 * @param {Array<String>} whitelistedKeys Strings that match keys to be explicitly whitelisted
 * @returns {Object|String} The scrubbed object or string
 */
function redact(value, sensitiveStrings = [], whitelistedKeys = [], mask = getRedactedValue) {
  // if its a string, attempt to parse as json
  if (typeof value === 'string') {
    try {
      value = JSON.parse(value)
    } catch (error) {
      // if the value is a string and not json parsable
      // check if the string has sensitive keywords
      // if it does just redact the whole thing
      return sensitiveStrings.some(r => value.toLowerCase().includes(r)) ? mask(value) : value
    }
  }

  if (value) {
    const outlawKeys = Object.keys(value)
      .filter(k => !whitelistedKeys.some(w => k === w)) // filter the keys that are whitelisted
      .filter(k => sensitiveStrings.some(r => k.toLowerCase().includes(r))) // filter the keys that do not contain sensitive strings

    Object.keys(value).forEach(k => {
      if (outlawKeys.includes(k)) {
      // if the key is one found to contain a sensitive string
        value[k] = mask(value[k])
      } else if (!whitelistedKeys.includes(k)) {
      // if the key is not whitelisted, redact as a nested object
        value[k] = redact(value[k], sensitiveStrings, whitelistedKeys)
      }
    })
  }

  return Object.freeze(value)
}

export default (value, ...rest) => {
  if (typeof value === 'object') {
    return redact(clone(value), ...rest)
  } else {
    return redact(value, ...rest)
  }
}
