import { defineNuxtRouteMiddleware, useNuxtApp, navigateTo } from '#app'
import isEmpty from 'lodash.isempty'
import { isLogoutRoute } from '@/utils/RouteQuery'
import qs from 'qs'

const layouts = ['identity']

const strategies = [
  {
    // /welcome WITHOUT lo = 1
    test: ({ query, store }) => store.state.identity.auth.isAuthenticated && isEmpty(query) && !store.state.identity.specialtyMismatch,
    action: () => navigateTo('/', { redirectCode: 307 }),
  },
  {
    // /welcome WITH lo = 1
    test: ({ query }) => isLogoutRoute(query),
    action: async ({ store, route }) => {
      delete route.query.lo
      const params = qs.stringify(route.query)

      await store.dispatch('navigation/signoutRedirect', `${route.path}${params ? '?' + params : ''}`)
    },
  },
]

export default defineNuxtRouteMiddleware((to) => {
  if (layouts.includes(to.meta.layout?.toLowerCase())) {
    const { $store } = useNuxtApp()

    const matchedStrategy = strategies.find(strategy => strategy.test({ query: to.query, store: $store }))

    if (matchedStrategy) {
      return matchedStrategy.action({ store: $store, route: to })
    }
  }
})
