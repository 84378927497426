import { gql } from '@apollo/client/core'

export const sendJobPreferences = gql`
  mutation sendPreferences($preferencesRequest:PreferencesRequest){
      sendPreferences(preferencesRequest:$preferencesRequest){
            message
      }
  }`

export default {
  sendJobPreferences,
}
