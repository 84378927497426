export const codes = {
  CHA: 'CHA',
  CHG: 'CHG',
  CHS: 'CHS',
  GMD: 'GMD',
  GMI: 'GMI',
  WBY: 'WBY',
  WMS: 'WMS',
}

export default {
  codes,
}
