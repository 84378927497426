import bodyClass from '@/utils/bodyClass'
import { openClass } from '@/utils/banner'

// ──── STATE ─────────────────────────────────────────────────────────────────────────────
export const state = () => ({
  closed: [],
  globalBannerHeight: 0,
})

// ──── ACTIONS ───────────────────────────────────────────────────────────────────────────
export const actions = {
  addBodyClass(context, bannerId) {
    bodyClass.add(openClass(bannerId))
  },

  hide({ commit, dispatch }, bannerId) {
    dispatch('removeBodyClass', bannerId)
    commit('SET_BANNER_CLOSED', bannerId)
  },

  removeBodyClass(context, bannerId) {
    bodyClass.remove(openClass(bannerId))
  },

  setGlobalBannerHeight({ commit }, height) {
    commit('SET_GLOBAL_BANNER_HEIGHT', height)
  },

  show({ commit, dispatch }, bannerId) {
    dispatch('addBodyClass', bannerId)
    commit('SET_BANNER_OPEN', bannerId)
  },

  updateUi({ dispatch, getters }, bannerId) {
    const action = getters.wasClosed(bannerId) ? 'removeBodyClass' : 'addBodyClass'
    dispatch(action, bannerId)
  },
}

// ──── MUTATIONS ─────────────────────────────────────────────────────────────────────────
export const mutations = {
  SET_BANNER_CLOSED(s, bannerId) {
    s.closed.push(bannerId)
  },

  SET_BANNER_OPEN(s, bannerId) {
    s.closed = s.closed.filter(id => id !== bannerId)
  },

  SET_GLOBAL_BANNER_HEIGHT(s, height) {
    s.globalBannerHeight = height
  },
}

// ──── GETTERS ───────────────────────────────────────────────────────────────────────────
export const getters = {
  wasClosed: s => bannerId => s.closed.includes(bannerId),
}
