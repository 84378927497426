import { codes } from './divisions'

const allowedFileTypes = [
  { extension: 'pdf', mimeType: 'application/pdf' },
  { extension: 'jpg', mimeType: 'image/jpeg' },
  { extension: 'jpeg', mimeType: 'image/jpeg' },
  { extension: 'png', mimeType: 'image/png' },
  { extension: 'heic', mimeType: 'image/heic' },
  { extension: 'svg', mimeType: 'image/svg+xml' },
  { extension: 'docx', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { extension: 'doc', mimeType: 'application/msword' },
  { extension: 'txt', mimeType: 'text/plain' },
  { extension: 'csv', mimeType: 'text/csv' },
  { extension: 'xls', mimeType: 'application/vnd.ms-excel' },
  { extension: 'xlsx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { extension: 'rtf', mimeType: 'application/rtf' },
]

export default {
  allowedDivisions: [
    codes.CHA,
    codes.CHG,
    codes.CHS,
    codes.WBY,
    codes.WMS,
    codes.GMD,
    codes.GMI,
  ],
  allowedExtensions: allowedFileTypes.map(fileType => fileType.extension),
  allowedMimeTypes: allowedFileTypes.map(fileType => fileType.mimeType).join(', '),
  pollInterval: 2000, // The polling interval, in milliseconds
  maxUploadSize: 50, // The maximum file size the UI will allow, in megabytes
  receivedStatuses: {
    notAccepted: 'NOT ACCEPTED',
    received: 'RECEIVED',
    verified: 'VERIFIED',
  },
  requestedStatuses: {
    pending: 'PENDING',
    requested: 'REQUESTED',
  },
  cvContentType: 'Curriculum Vitae (Resume)',
}
