import { gql } from '@apollo/client/core'

export const sendLead = gql`
  mutation sendLead($leadRequest: LeadRequest) {
    sendLead(leadRequest: $leadRequest) {
      id
      timestamp
      submittedLead {
          division
      }
  }
}
`

export default {
  sendLead,
}
