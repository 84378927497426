import { gql } from '@apollo/client/core'
export const providerData = gql`
query getProviderData($providerId:String, $integrationId:String) {
  getProviderData{
    workingPreferences(providerId:$providerId, integrationId:$integrationId) {
      availableToWork
      nextAvailableDate
      providerWorkPreferences
      activeLicenses
      preferredStates
    } 
  }
}`

export default {
  providerData,
}
