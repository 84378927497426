import { gql } from '@apollo/client/core'

export const sendWorkPreferences = gql`
  mutation patchProvidersId($input: PatchProvidersInput) {
    patchProvidersId(input: $input) {
      availableToWork
    }
  }
`

export default {
  sendWorkPreferences,
}
