import { gql } from '@apollo/client/core'

export const documentRequestsByProviderIdQuery = gql`
  query documentRequestsByProviderId($providerId: ID!) {
    documentRequestsByProviderId(providerId: $providerId) {
      id,
      category,
      contentType,
      dmsDocumentId,
      externalContentType,
      filename,
      internalContentType,
      label,
      metadata {
        specialty,
        state,
        subCategory,
        taxYear,
        checkDate,
        checkNumber
      }
      receivedDate,
      receivedText,
      relatedEntities{
        id,
        origin,
      },
      status,
    }
  }
`

export default {
  documentRequestsByProviderIdQuery,
}
