import { defineNuxtRouteMiddleware, useNuxtApp, navigateTo } from '#app'

const layouts = ['dashboard', 'pna']

export default defineNuxtRouteMiddleware((to) => {
  if (layouts.includes(to.meta.layout?.toLowerCase())) {
    const { $store } = useNuxtApp()

    if (to.fullPath !== '/' && $store.state.account.disableAlliedUsers) {
      return navigateTo('/')
    }
  }
})
