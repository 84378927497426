/**
 * Cleans an array or object of empty values and __typename properties
 *
 * NOTE: This may be more useful as a global util as removing __typename properties from query
 *  payloads may be necessary elsewhere.
 *
 * @param {*} data - The data requiring cleaning
 * @returns Cleaned data
 */
export const cleanData = data => {
  if (typeof data === 'object' && !(data instanceof Date)) {
    if (Array.isArray(data)) {
      return data.filter(x => x).map(item => cleanData(item))
    }

    return Object.entries(data).reduce((acc, [key, value]) => {
      if (key !== '__typename' && !!value) {
        acc[key] = cleanData(value)
      } else if (value === 0 || value === '') {
        acc[key] = value
      } else if (value === false) {
        acc[key] = false
      }
      return acc
    }, {})
  }

  return data
}
