
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEButton from '@/components/pde/PDEButton.vue'

export default {
  name: 'Error',

  components: {
    PDEIcon,
    PDEButton,
  },

  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 999,
      }),
    },
  },

  computed: {
    displayErrorCode() {
      return !['genericError', 'unexpectedError'].includes(this.errorType) && this.error.statusCode !== 410
    },

    errorType() {
      if (this.error.statusCode === 0) return 'genericError'
      if (this.error.statusCode === 404) return 'pageNotFound'
      if (this.error.statusCode === 410) return 'gone'
      if (this.error.statusCode === 503) return 'serviceUnavailable'

      return 'unexpectedError'
    },

    hasCta() {
      return this.$t(`error.${this.errorType}.cta.action`) !== `error.${this.errorType}.cta.action`
    },

  },

  methods: {
    ctaAction() {
      window.location.assign(this.$t(`error.${this.errorType}.cta.action`))
    },
  },
}
