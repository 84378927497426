import base64url from 'base64url'

export default token => {
  if (typeof token !== 'string') return token

  const tokenArray = token.split('.')

  if (tokenArray.length !== 3) return

  try {
    const decodedString = base64url.decode(tokenArray[1])
    return JSON.parse(decodedString)
  } catch (error) { }
}
