export const scriptExists = src => Boolean(document.querySelector(`script[src="${src}"]`))

export const appendScript = async options => {
  const defaults = {
    type: 'text/javascript',
  }

  const attributes = {
    ...defaults,
    ...options,
  }

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line prefer-promise-reject-errors
    if (scriptExists(attributes.src)) return reject([null, `Script with src ${attributes.src} already loaded`])

    const script = document.createElement('script')

    for (const property in attributes) {
      script.setAttribute(property, attributes[property])
    }

    script.addEventListener('load', () => resolve([`Loaded script with src ${attributes.src}`]))

    // eslint-disable-next-line prefer-promise-reject-errors
    script.addEventListener('error', () => reject([null, `Failed to load script with src ${attributes.src}`]))

    document.head.appendChild(script)
  })
}
