import { gql } from '@apollo/client/core'

export const providerAssignmentDetailsData = gql`
  query providerAssignmentDetailsData($providerId:String!){
    providerAssignmentDetailsData(providerId:$providerId){
      id
      data {
        assignment {
          Pipeline_State
          Closed_Reason
        }
        shifts {
          assignmentId
          endDate
          endTime
          startDate
          startTime
          worksites {
            city
            description
            label
            state
            worksiteId
          }
        }
      }
    }
  }
`

export default {
  providerAssignmentDetailsData,
}
