import { dayjs } from '@/plugins/dayjs'

export const formatDateToMMMDDYYYY = dateStr => dayjs(dateStr, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('MMM D, YYYY')
export const formatDateToMMMDD = dateStr => dayjs(dateStr, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('MMM D')
export const formatDateToMMDDYYYY = dateStr => dayjs(dateStr, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('MM/DD/YYYY')
export const formatTimeToHMMa = timeStr => dayjs(timeStr, 'HH:mm:ss').format('h:mm a')
export const formatTimeToHMMA = timeStr => dayjs(timeStr, 'HH:mm:ss').format('h:mm A')

export const isCompleteAddress = address => !!(
  address &&
  address.address1 &&
  address.city &&
  address.state &&
  address.zipCode
)

export const getAddressString = address => {
  if (!address) return ''

  const addressLine = [address.address1, address.address2].filter(Boolean).join(' ')

  const addArray = [
    addressLine ? `${addressLine},` : '',
    address.city ? `${address.city},` : '',
    address.state,
    address.zipCode,
  ].filter(Boolean)

  return addArray.join(' ')
}

export const getDateTimeStr = (dateStr, timeStr) => {
  let formattedDateTimeStr = ''

  if (dateStr) {
    formattedDateTimeStr = formatDateToMMDDYYYY(dateStr)

    if (timeStr) {
      formattedDateTimeStr += ` ${timeStr}`
    }
  }

  return formattedDateTimeStr
}

export const numberSort = direction => (a, b) => (a - b) * direction

export const stringSort = direction => (a, b) => a.localeCompare(b) * direction

export const dateSort = direction => (aDateTimeStr, bDateTimeStr) => {
  const aDateTime = dayjs(aDateTimeStr)
  const bDateTime = dayjs(bDateTimeStr)

  return (aDateTime.valueOf() - bDateTime.valueOf()) * direction
}

export const sortingFns = {
  startDate: direction => (a, b) => {
    const aStartDateTime = a && a.startDate ? getDateTimeStr(a.startDate, a.startTime) : getDateTimeStr(new Date())
    const bStartDateTime = b && b.startDate ? getDateTimeStr(b.startDate, b.startTime) : getDateTimeStr(new Date())

    return dateSort(direction)(aStartDateTime, bStartDateTime)
  },
}

export const sort = (items, { field, direction, resolveEqualItemsWith }) => {
  const dir = direction === 'asc' ? 1 : -1
  const sortFn = sortingFns[field](dir)

  return [...items].sort((a, b) => {
    let comparison = sortFn(a, b)

    if (comparison === 0 && resolveEqualItemsWith) {
      const { resolveSortFn, propToCompare } = resolveEqualItemsWith

      if (resolveSortFn && propToCompare) comparison = resolveSortFn(dir)(a[propToCompare], b[propToCompare])
    }

    return comparison
  })
}
