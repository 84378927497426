import { generateRandomString } from '@/helpers/randomString'
import crypto from 'crypto'
import { base64UrlFromBase64 } from '@/utils/base64'

export default ({ id = '' }) => {
  const codeVerifier = `${generateRandomString(50)}_${id}`
  const base64Digest = crypto
    .createHash('sha256')
    .update(codeVerifier)
    .digest('base64')
  return {
    code_verifier: codeVerifier,
    code_challenge: base64UrlFromBase64(base64Digest),
  }
}
