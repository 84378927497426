import { dayjs } from '@/plugins/dayjs'

export const getTimelinePosition = (startDate, endDate) => {
  const now = dayjs()
  const startDay = dayjs(startDate)
  const endDay = dayjs(endDate)
  const isPastEvent = endDay.isBefore(now, 'day')
  const IsFutureEvent = startDay.isAfter(now, 'day')
  return isPastEvent ? 'Past' : IsFutureEvent ? 'Future' : 'Active'
}
