export default {
  feature: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/efb594259a24/launch-ENcaba508cd4fb4c5694ee93d1e6715d5c-development.min.js',
    alliedDivisionCodes: ['wms'],
    featureToggleSpecialties: {
      'Reproductive Endocrinology': ['AssignmentDetails'],
      Urology: ['AssignmentDetails'],
      'Plastic Surgery': ['AssignmentDetails'],
      Neonatology: ['AssignmentDetails'],
      'Maternal Fetal Medicine/Perinatology': ['AssignmentDetails'],
      Gynecology: ['AssignmentDetails'],
      Otolaryngology: ['AssignmentDetails'],
      Obstetrics: ['AssignmentDetails'],
      'Pediatric Urology': ['AssignmentDetails'],
      'Pediatric Otolaryngology': ['AssignmentDetails'],
      'Gynecologic Oncology': ['AssignmentDetails'],
      'Obstetrics/Gynecology': ['AssignmentDetails'],
    },
  },
  dev: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/efb594259a24/launch-ENcaba508cd4fb4c5694ee93d1e6715d5c-development.min.js',
    alliedDivisionCodes: ['wms'],
    featureToggleSpecialties: {
      'Reproductive Endocrinology': ['AssignmentDetails'],
      Urology: ['AssignmentDetails'],
      'Plastic Surgery': ['AssignmentDetails'],
      Neonatology: ['AssignmentDetails'],
      'Maternal Fetal Medicine/Perinatology': ['AssignmentDetails'],
      Gynecology: ['AssignmentDetails'],
      Otolaryngology: ['AssignmentDetails'],
      Obstetrics: ['AssignmentDetails'],
      'Pediatric Urology': ['AssignmentDetails'],
      'Pediatric Otolaryngology': ['AssignmentDetails'],
      'Gynecologic Oncology': ['AssignmentDetails'],
      'Obstetrics/Gynecology': ['AssignmentDetails'],
    },
  },
  stage: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/efb594259a24/launch-ENcaba508cd4fb4c5694ee93d1e6715d5c-development.min.js',
    alliedDivisionCodes: ['wms'],
    featureToggleSpecialties: {
      'Reproductive Endocrinology': ['AssignmentDetails'],
      Urology: ['AssignmentDetails'],
      'Plastic Surgery': ['AssignmentDetails'],
      Neonatology: ['AssignmentDetails'],
      'Maternal Fetal Medicine/Perinatology': ['AssignmentDetails'],
      Gynecology: ['AssignmentDetails'],
      Otolaryngology: ['AssignmentDetails'],
      Obstetrics: ['AssignmentDetails'],
      'Pediatric Urology': ['AssignmentDetails'],
      'Pediatric Otolaryngology': ['AssignmentDetails'],
      'Gynecologic Oncology': ['AssignmentDetails'],
      'Obstetrics/Gynecology': ['AssignmentDetails'],
    },
  },
  beta: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/efb594259a24/launch-EN7a2145ac429742e38b5b24ac1d9ec2ae.min.js',
    alliedDivisionCodes: ['wms'],
    logRocket: 'xfgtax/pde-ui-weatherby',
    salesforceUrl: 'https://beta-my.weatherbyhealthcare.com',
    featureToggleSpecialties: {
      'Reproductive Endocrinology': ['AssignmentDetails'],
      Urology: ['AssignmentDetails'],
      'Plastic Surgery': ['AssignmentDetails'],
      Neonatology: ['AssignmentDetails'],
      'Maternal Fetal Medicine/Perinatology': ['AssignmentDetails'],
      Gynecology: ['AssignmentDetails'],
      Otolaryngology: ['AssignmentDetails'],
      Obstetrics: ['AssignmentDetails'],
      'Pediatric Urology': ['AssignmentDetails'],
      'Pediatric Otolaryngology': ['AssignmentDetails'],
      'Gynecologic Oncology': ['AssignmentDetails'],
      'Obstetrics/Gynecology': ['AssignmentDetails'],
    },
  },
  prod: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/efb594259a24/launch-EN7a2145ac429742e38b5b24ac1d9ec2ae.min.js',
    alliedDivisionCodes: ['wms'],
    logRocket: 'xfgtax/pde-ui-weatherby',
    salesforceUrl: 'https://my.weatherbyhealthcare.com',
    featureToggleSpecialties: {
      'Reproductive Endocrinology': ['AssignmentDetails'],
      Urology: ['AssignmentDetails'],
      'Plastic Surgery': ['AssignmentDetails'],
      Neonatology: ['AssignmentDetails'],
      'Maternal Fetal Medicine/Perinatology': ['AssignmentDetails'],
      Gynecology: ['AssignmentDetails'],
      Otolaryngology: ['AssignmentDetails'],
      Obstetrics: ['AssignmentDetails'],
      'Pediatric Urology': ['AssignmentDetails'],
      'Pediatric Otolaryngology': ['AssignmentDetails'],
      'Gynecologic Oncology': ['AssignmentDetails'],
      'Obstetrics/Gynecology': ['AssignmentDetails'],
    },
  },
}
