export const sendCampaign = async ({ payload, jwt, pes, httpRequest }) => {
  try {
    return await httpRequest({
      url: `${pes}/notifications/send/email`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payload),
    })
  } catch (error) {
    return { status: 500, message: error.message }
  }
}
