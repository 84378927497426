export default {
  maxlength: (str, len) => {
    return str.trim().substr(0, parseInt(len, 10))
  },
  number: str => {
    return str.replace(/\D/g, '')
  },
  /**
   * Transforms a string into one of the folowing formats:
   *   - na-hyphenated (default): With hyphens, i.e. ###-###-####
   *   - na: Standard format for North American countries, i.e. (###) ###-####
   *   - us-international: With single-digit country code, i.e. +# ###-###-####
   * Note:
   *   - Will remove any characters other than numbers, parentheses, or hyphens
   *   - Will trim `str` if it's longer than 10 digits (non-us-international) and 11 digits (us-international)
   *
   * @param {string} str The string to format
   * @param {string} format [format=na-hyphenated] The desired format
   * @returns {string} The formatted string
   */
  phone: (str, format = 'na-hyphenated') => {
    const hyphenated = format === 'na-hyphenated'
    const na = format === 'na'
    const international = format === 'us-international'

    const formatDelimiters = [
      {
        condition: hyphenated,
        delimiters: {
          3: '-',
          6: '-',
        },
      },
      {
        condition: na,
        delimiters: {
          0: '(',
          3: ') ',
          6: '-',
        },
      },
      {
        condition: international,
        delimiters: {
          0: '+',
          1: ' ',
          4: '-',
          7: '-',
        },
      },
    ]

    const addBefore = formatDelimiters?.find(item => item.condition === true)?.delimiters
    const removedNonDigits = str ? str.replace(/\D/g, '') : ''
    const maxDigits = international ? 11 : 10

    let formattedPhone = ''

    for (let i = 0; i < removedNonDigits.length && i < maxDigits; i++) {
      formattedPhone += `${addBefore?.[i] || ''}${removedNonDigits[i]}`
    }

    return formattedPhone || str
  },
  ssn: str => {
    // Will format `str` to ###-##-####
    // Will remove any characters other than numbers or dashes
    // Will trim `str` if it's longer than 9 digits
    const addBefore = {
      3: '-',
      5: '-',
    }
    const maxDigits = 9

    let formattedSsn = ''

    const removedNonDigits = str.replace(/\D/g, '')

    for (let i = 0; i < removedNonDigits.length && i < maxDigits; i++) {
      formattedSsn += (addBefore[i] || '') + removedNonDigits[i]
    }

    return formattedSsn || str
  },
  date: str => {
    const addAfter = {
      1: '/',
      3: '/',
    }
    const maxDigits = 8

    let formattedDate = ''

    str = str.replace(/\D/g, '')

    for (let i = 0; i < str.length && i < maxDigits; i++) {
      formattedDate += str[i] + (addAfter[i] || '')
    }

    return formattedDate
  },
}
