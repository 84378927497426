import refreshTokens from '@/utils/refreshTokens'
import generateSignoutRedirectUrl from '@/utils/generateSignoutRedirectUrl'

export const logoutChecker = async ({ dispatch, rootGetters }, { route = {} }) => {
  const isExpired = rootGetters['identity/sessionExpired']()

  if (isExpired) {
    const keepUserLoggedIn = window.localStorage.getItem('chg_keep_logIn') === 'true'
    const refreshToken = window.localStorage.getItem('chg_refresh_token')

    if (keepUserLoggedIn && refreshToken) {
      try {
        const respFromTokens = await refreshTokens(refreshToken)
        await dispatch('identity/setOidc', respFromTokens.data, { root: true })
        return
      } catch (error) {
        console.error(`Error refreshing user token: ${error.message}`)
      }
    }

    dispatch('navigation/signoutRedirect', generateSignoutRedirectUrl({ route }), { root: true })
  }
}
