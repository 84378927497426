export default {
  feature: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/d0f7cf79d098/launch-fa20882a2bfd-development.min.js',
    alliedDivisionCodes: [],
  },
  dev: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/d0f7cf79d098/launch-fa20882a2bfd-development.min.js',
    alliedDivisionCodes: [],
  },
  stage: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/d0f7cf79d098/launch-f622c8c50c16-staging.min.js',
    alliedDivisionCodes: [],
  },
  beta: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/d0f7cf79d098/launch-f0c3222e3f13.min.js',
    alliedDivisionCodes: [],
    logRocket: 'xfgtax/pde-ui-rnnetwork',
    salesforceUrl: 'https://beta-my.gmedical.com',
  },
  prod: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/d0f7cf79d098/launch-f0c3222e3f13.min.js',
    alliedDivisionCodes: [],
    logRocket: 'xfgtax/pde-ui-rnnetwork',
    salesforceUrl: 'https://my.gmedical.com',
  },
}
