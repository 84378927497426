import { gql } from '@apollo/client/core'

export const travelRequestsByProviderIdQuery = gql`
  query providerTravelData($oktaID: String, $authorization: String, $brand: String, $sort: Boolean) {
    providerTravelData(oktaID: $oktaID, authorization: $authorization, brand: $brand, sort: $sort) {
      PDFUrl,
      StartDate,
      EndDate,
      CreatedDate,
      ModifiedDate,
      DepartureCity,
      ArrivalCity,
      clientCommonName,
      clientAddress1,
      clientCity,
      clientState,
      clientZip,
      clientCountry,
      PNR,
      assignmentId,
      status,
      portalStatus,
      Bookings {
        date,
        data {
          type,
          id,
          status,
          code,
          name,
          carType,
          sortDate,
          confirmationNumber,
          tsaPreCheck,
          flightNumber,
          loyaltyId,
          address,
          province,
          country,
          phone,
          departureDateAndTime,
          departureCity,
          departureState,
          departureAirportCode,
          arrivalLocation,
          departureLocation,
          arrivalCountry,
          departureCountry,
          arrivalDateAndTime,
          arrivalCity,
          arrivalState,
          arrivalAirportCode,
          airlineName,
          baggageLink,
          checkinLink,
          checkinDateTime,
          checkoutDateTime,
          isPickup,
          isCheckin,
          pickup {
            address,
            city,
            province,
            country,
            time,
            airportCode
          },
          dropoff {
            address,
            city,
            province,
            country,
            time,
            airportCode
          }
        }
      }
    }
  }
`

export const travelRequestsResendHotelPaymentQuery = gql`
  query resendPayment($pnr: String) {
    resendPayment(pnr: $pnr) {
      list {
        resendUrl
      }
      totalCount
    }
  }
`

export default {
  travelRequestsByProviderIdQuery,
  travelRequestsResendHotelPaymentQuery,
}
