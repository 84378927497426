import { gql } from '@apollo/client/core'

export const generateDocumentRequest = gql`
  mutation generateDocumentRequest($documentRequestInput:DocumentRequestInput){
      generateDocumentRequest(documentRequestInput:$documentRequestInput){
        id
        contentType
        status
      }
  }`

export default {
  generateDocumentRequest,
}
