import { gql } from '@apollo/client/core'

export const getTimeEntryUrl = gql`
  query timeEntryUrl($oktaId: String!, $assignmentId: String!) {
    v2GetTimeEntryUrl(oktaId: $oktaId, assignmentId: $assignmentId) {
      timeEntryUrl
    }
  }
`

export default { getTimeEntryUrl }
