export const getDefaultState = () => ({
  currentToast: {
    show: false,
    content: '',
    duration: 0,
    type: 'success',
    xPosition: 'center',
    yPosition: 'top',
    aaTag: '',
    showCloseButton: true,
  },
  toasts: [],
})

// ──── STATE ─────────────────────────────────────────────────────────────────────────────
export const state = getDefaultState

// ──── ACTIONS ───────────────────────────────────────────────────────────────────────────
export const actions = {
  addToast({ commit }, toast) {
    commit('ADD_TOAST', toast)
  },

  openNextToast(context) {
    if (context.state.toasts.length > 0) {
      context.commit('SHOW_NEXT_TOAST')
      const duration = context.state.currentToast.duration

      if (duration) {
        context.dispatch('closeToastAfterDuration', duration)
      }
    }
  },

  openToast({ commit, dispatch }, toast) {
    commit('SHOW_TOAST', toast)

    if (toast.duration) {
      dispatch('closeToastAfterDuration', toast.duration)
    }
  },

  closeToastAfterDuration({ commit }, duration = 3000) {
    setTimeout(() => {
      commit('HIDE_TOAST')
    }, duration)
  },

  closeToast({ commit }) {
    commit('HIDE_TOAST')
  },

  resetState({ commit }) {
    commit('RESET_STATE')
  },
}

// ──── MUTATIONS ─────────────────────────────────────────────────────────────────────────
export const mutations = {
  ADD_TOAST(s, toast) {
    s.toasts.push(toast)
  },

  SHOW_NEXT_TOAST(s) {
    const toast = s.toasts.shift()

    s.currentToast = {
      ...toast,
      show: true,
    }
  },

  SHOW_TOAST(s, toast) {
    s.currentToast = {
      ...toast,
      show: true,
    }
  },

  HIDE_TOAST(s) {
    s.currentToast.show = false
  },

  RESET_STATE(s) {
    Object.assign(s, getDefaultState())
  },
}

// ──── GETTERS ───────────────────────────────────────────────────────────────────────────
export const getters = {
  currentToast: ({ currentToast }) => currentToast,
}
