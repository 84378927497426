
import PDESpinner from '@/components/pde/PDESpinner.vue'
import { mapActions } from 'vuex'
import isEmpty from 'lodash.isempty'

export default {
  name: 'PDEButton',
  components: { PDESpinner },

  props: {
    styleType: {
      type: String,
      default: 'fill',
      validator: (value) => ['fill', 'line', 'text'].indexOf(value) !== -1,
    },

    borderRadius: {
      type: Number,
      default: 3,
      validator: (value) => [0, 2, 3, 4, 6, 8, 24],
    },

    showSpinner: {
      type: Boolean,
      default: false,
    },

    spinnerDataTest: {
      type: String,
      default: 'pde-button-spinner',
    },

    spinnerClass: {
      type: String,
      default: 'ml-8',
    },

    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium', 'normal', 'smallMobile'].indexOf(value) !== -1,
    },

    color: {
      type: String,
      default: 'normal',
      validator: (value) =>
        ['primary', 'secondary', 'normal'].indexOf(value) !== -1,
    },

    analyticsData: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    borderRadiusClasses() {
      return `rounded-${this.borderRadius}`
    },

    sizeClasses() {
      const sizes = {
        smallMobile: 'text-12 px-16 h-32 md:h-32',
        small: 'text-12 px-16 h-48 md:h-32',
        normal: 'text-14 px-16 h-32',
        medium: 'text-16 px-24 h-48',
      }

      return sizes[this.size]
    },

    colorClasses() {
      const styles = {
        fill: {
          primary:
            'border-0 text-pureblack-0 bg-primary-500 primaryhover:hover:bg-primary-400 active:bg-primary-400 disabled:text-pureblack-5 disabled:bg-pureblack-25 primaryhover:disabled:bg-pureblack-25',
          secondary:
            'border-0 text-black-800 bg-primary-50 primaryhover:hover:bg-primary-200 active:bg-primary-200 disabled:text-pureblack-5 disabled:bg-pureblack-25 primaryhover:disabled:bg-pureblack-25 disabled:border-1 disabled:border-solid',
          normal:
            'border-0 text-black-800 bg-pureblack-15 primaryhover:hover:bg-pureblack-20 active:bg-pureblack-20 disabled:text-pureblack-5 disabled:bg-pureblack-25 primaryhover:disabled:bg-pureblack-25',
        },
        line: {
          primary:
            'border-1 border-solid text-primary-500 bg-pureblack-0 border-primary-500 primaryhover:hover:bg-primary-50 active:bg-primary-50 disabled:bg-transparent primaryhover:disabled:bg-transparent disabled:text-pureblack-35 disabled:border-pureblack-15',
          secondary:
            'border-1 border-solid text-primary-500 bg-transparent border-primary-100 primaryhover:hover:bg-primary-50 active:bg-primary-50 disabled:bg-transparent primaryhover:disabled:bg-transparent disabled:text-pureblack-35 disabled:border-pureblack-15',
          normal:
            'border-1 border-solid text-black-800 bg-transparent border-pureblack-15 primaryhover:hover:bg-pureblack-15 primaryhover:hover:border-pureblack-20 disabled:bg-transparent primaryhover:disabled:bg-transparent disabled:text-pureblack-35 disabled:border-pureblack-15',
        },
        text: {
          primary:
            'border-0 text-primary-500 bg-transparent primaryhover:hover:bg-primary-50 active:bg-primary-50 disabled:bg-transparent primaryhover:disabled:bg-transparent disabled:text-pureblack-35',
          secondary:
            'border-0 text-primary-500 bg-transparent primaryhover:hover:bg-primary-50 active:bg-primary-50 disabled:bg-transparent primaryhover:disabled:bg-transparent disabled:text-pureblack-35',
          normal:
            'border-0 text-black-800 bg-transparent primaryhover:hover:bg-pureblack-15 disabled:bg-transparent primaryhover:disabled:bg-transparent disabled:text-pureblack-35',
        },
      }

      return styles[this.styleType][this.color]
    },
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    handleAnalytics() {
      if (!isEmpty(this.analyticsData)) {
        this.sendClickEvent({
          name: this.analyticsData.clickName,
          text: this.analyticsData.clickText,
          area: this.analyticsData.clickArea,
          position: this.analyticsData.clickPosition,
          detail: this.analyticsData.clickDetail,
        })
      }
    },
  },
}
