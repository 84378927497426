import base64url from 'base64url'

export const base64UrlDecode = value => {
  if (typeof value !== 'string') return value
  return base64url.decode(value)
}

export const base64UrlEncode = value => {
  if (typeof value !== 'string') return value
  return base64url(value)
}

export const base64UrlFromBase64 = value => {
  if (typeof value !== 'string') return value
  return base64url.fromBase64(value)
}
