import { stringify } from '@/utils/params'
import { base64UrlEncode } from '@/utils/base64'

const generateSignoutRedirectUrl = ({ route }) => {
  const path = route.path && route.path !== '/' ? route.path : ''
  delete route?.query?.lo
  const query = route.query && Object.keys(route.query).length ? `?${stringify(route.query)}` : ''
  const entry = `${path}${query}`

  const url = `/welcome/${entry ? '?entry=' + base64UrlEncode(entry) : ''}`

  return url
}

export default generateSignoutRedirectUrl
