export const formSubmit = wrapper => {
  wrapper.find('form').trigger('submit')
}

export const test = x => x

export const inputEntry = async (wrapper, selector, value, blur) => {
  const elemWrapper = wrapper.find(selector)
  const tag = elemWrapper.element.tagName.toLowerCase()
  const type = elemWrapper.element.type

  if (type === 'date') {
    const date = new Date(value)

    if (date.toString() !== 'Invalid Date') {
      value = date.toISOString().slice(0, 10)
    }
  }

  await elemWrapper.setValue(value)

  // Selects need a change event
  if (tag === 'select') {
    elemWrapper.element.dispatchEvent(new Event('change'))
  } else if (blur) {
    await inputBlur(wrapper, selector)
  }
}

export const inputBlur = async (wrapper, selector) => await wrapper.find(selector).trigger('blur')

export const inputKeyUp = async (wrapper, selector, keyCode) => {
  const elemWrapper = wrapper.find(selector)

  await elemWrapper.trigger('keyup', {
    key: keyCode,
  })
}

export default {
  formSubmit,
  test,
  inputEntry,
  inputBlur,
  inputKeyUp,
}
