import global from './_global.json'
import account from './account.json'
import documents from './documents.json'
import error from './error.json'
import help from './help.json'
import identity from './identity.json'
import myteams from './myteams.json'
import timeEntry from './time-entry.json'

export default {
  ...global,
  ...account,
  ...documents,
  ...error,
  ...help,
  ...identity,
  ...myteams,
  ...timeEntry,
}
