export default value => {
  if (isNaN(value) || value.toString().length !== 10) return false

  // turn input into array of numbers
  value = Array.from(String(value), Number)

  // Start with the original NPI number, the last digit is the check digit and is not used in the calculation.
  let npi9 = value.slice(0, 9)

  // Step 1: Double the value of the alternate digits
  const evenNumbers = [0, 2, 4, 6, 8]
  evenNumbers.forEach(v => { npi9[v] = npi9[v] * 2 })

  // Step 2: Add all the doubled and unaffected individual digits from step 1 plus the constant number 24.
  npi9 = Array.from(npi9.join(''), Number) // flatten then re-split array back into individual digits.
  const npiSum = npi9.reduce((acc, current) => { return acc + current }, 24)

  // Step 3: Subtract the total obtained in step 2 from the next higher number ending in zero, the result is the check digit.
  const next10Divisible = Math.ceil(npiSum / 10) * 10

  // If the difference of the next 10 divisible - npiSum = the last digit in the original number, it is valid.
  // If the sum is 0 then it is invalid.
  return next10Divisible - npiSum === value[9]
}
