import merge from 'lodash.merge'

import baseConfig from './brands/base.config'
import comphealth from './brands/comphealth.config'
import weatherby from './brands/weatherby.config'
import gmedical from './brands/gmedical.config'

export { default as documents } from './documents.config'
export { default as divisions } from './divisions'
export { default as imlcStates } from './imlcStates'
export { default as logRocketRedactValues } from './logRocketRedactValues'
export { default as salesforceUrls } from './salesforce-urls'

export default {
  comphealth: merge({}, baseConfig, comphealth),
  gmedical: merge({}, baseConfig, gmedical),
  weatherby: merge({}, baseConfig, weatherby),
}
