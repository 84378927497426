import { defineNuxtPlugin } from '#app'
import LogRocket from 'logrocket'
import isEmpty from 'lodash.isempty'
import redact from '../utils/redact.js'

export default defineNuxtPlugin({
  dependsOn: ['globalVars'],
  parallel: true,
  setup({ $globalVars, vueApp }) {
    LogRocket.init($globalVars.logRocket, {
      release: $globalVars.version,
      network: {

        requestSanitizer: request => {
          // if request body contains password
          const requestBody = request.body
          const redactKeys = $globalVars.logRocketRedactValues

          try {
            if (!isEmpty(requestBody)) {
              const redactBody = redact(requestBody, redactKeys)

              request.body = JSON.stringify(redactBody)
            }
          } catch (error) {
            /* eslint-disable-next-line */
            console.error(error);
          }

          // otherwise log the request normally
          return request
        },
      },
    })

    vueApp.config.errorHandler = function (err, instance, info) {
      console.group('Unhandled error from vue errorHandler')
      console.error(err)
      console.log('instance', instance)
      // `info` is a Vue-specific error code in production.
      // Code to string mapping can be found here: https://vuejs.org/error-reference/#runtime-errors
      console.log('info', info)
      console.groupEnd()

      LogRocket.captureException(err)
    }
  },
})
