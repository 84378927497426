import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "overlay absolute z-64 inset-0 bg-white"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([$options.flexCentered, $options.overlayContainer ])
    }, [
      ($props.overlay)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      ($props.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([[$options.primary], "relative w-24 h-24"]),
            "data-test": "pdeSpinner",
            role: "status"
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("svg", {
              class: "animate-spin text-white",
              xmlns: "http://www.w3.org/2000/svg",
              fill: "none",
              viewBox: "0 0 24 24"
            }, [
              _createElementVNode("circle", {
                class: "opacity-30",
                cx: "12",
                cy: "12",
                r: "10",
                stroke: "currentColor",
                "stroke-width": "4"
              }),
              _createElementVNode("path", {
                class: "opacity-75",
                fill: "currentColor",
                d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              })
            ], -1)
          ]), 2))
        : _createCommentVNode("", true)
    ], 2),
    (!$props.loading || ($props.loading && $props.overlay))
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ]))
}