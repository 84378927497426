import { gql } from '@apollo/client/core'

export const lastLoginDateRequest = gql`
  query getLastLoginDate($oktaId: String!) {
    getLastLogin(oktaId: $oktaId) {
      lastLogin
    }
  }
`

export default {
  lastLoginDateRequest,
}
