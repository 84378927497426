import { defineNuxtPlugin } from '#app'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import arraySupport from 'dayjs/plugin/arraySupport'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import minMax from 'dayjs/plugin/minMax'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(advancedFormat)
dayjs.extend(arraySupport)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(minMax)
dayjs.extend(utc)
dayjs.extend(weekOfYear)
dayjs.extend(isBetween)
dayjs.extend(timezone)

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.provide('dayjs', dayjs)
  },
})

export { dayjs }
