export default {
  feature: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/4a18ec360d73/launch-EN4b660a04abde4b96a7ed58326b33a481-development.min.js',
    alliedDivisionCodes: ['cha', 'gra'],
    featureFlags: {
      disableAlliedUsers: false,
    },
  },
  dev: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/4a18ec360d73/launch-EN4b660a04abde4b96a7ed58326b33a481-development.min.js',
    alliedDivisionCodes: ['cha', 'gra'],
    featureFlags: {
      disableAlliedUsers: false,
    },
  },
  stage: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/4a18ec360d73/launch-EN4b660a04abde4b96a7ed58326b33a481-development.min.js',
    alliedDivisionCodes: ['cha', 'gra'],
    featureFlags: {
      disableAlliedUsers: false,
    },
  },
  beta: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/4a18ec360d73/launch-EN6d0b04f177a547be990898ec6eee11f6.min.js',
    alliedDivisionCodes: ['cha', 'gra'],
    featureFlags: {
      disableAlliedUsers: false,
    },
    salesforceUrl: 'https://beta-my.comphealth.com',
  },
  prod: {
    adobeLaunchUrl: '//assets.adobedtm.com/831ff888aebe/4a18ec360d73/launch-EN6d0b04f177a547be990898ec6eee11f6.min.js',
    alliedDivisionCodes: ['cha', 'gra'],
    featureFlags: {
      disableAlliedUsers: false,
    },
    salesforceUrl: 'https://my.comphealth.com',
  },
}
