export default {
  feature: {
    apiUrl: 'https://api.dev.pde.aws.chgit.com',
    cache: 'false',
    calendarSubscriptionMobileUrl: 'https://pde-experience-service.dev.platform.aws.chgit.com',
    calendarSubscriptionUrl: 'webcal://pde-experience-service.dev.platform.aws.chgit.com',
    dataGeneratedUsernamePrefix: 'chgpdeteam+gen-',
    documentsServiceUrl: 'https://pde-documents-service.dev.platform.aws.chgit.com',
    experienceServiceUrl: 'https://pde-experience-service.dev.platform.aws.chgit.com',
    featureFlags: {},
    identityService: 'https://pde-identity-service-next.dev.platform.aws.chgit.com',
    launchDarklyClientSideId: '62cc4b97521cf9111ea546d0',
    logRocket: 'xfgtax/pde-ui-dev',
    oidcClientId: '0oawjd9w93UQde5th0h7',
    oktaBaseUrl: 'https://chghealthcare.oktapreview.com',
    oktaMfaGroupName: 'PDE MFA Users',
    providersService: 'https://pde-providers-service-next.dev.platform.aws.chgit.com',
    workdayUrl: 'https://chghealthcare.oktapreview.com/home/workday/0oa1dblhixthtkQMM0h8/15',
  },
  dev: {
    apiUrl: 'https://api.dev.pde.aws.chgit.com',
    cache: 'false',
    calendarSubscriptionMobileUrl: 'https://pde-experience-service.dev.platform.aws.chgit.com',
    calendarSubscriptionUrl: 'webcal://pde-experience-service.dev.platform.aws.chgit.com',
    dataGeneratedUsernamePrefix: 'chgpdeteam+gen-',
    documentsServiceUrl: 'https://pde-documents-service.dev.platform.aws.chgit.com',
    experienceServiceUrl: 'https://pde-experience-service.dev.platform.aws.chgit.com',
    featureFlags: {},
    identityService: 'https://pde-identity-service-next.dev.platform.aws.chgit.com',
    launchDarklyClientSideId: '62cc4b83820bfb11058982c3',
    logRocket: 'xfgtax/pde-ui-dev',
    oidcClientId: '0oawjd9w93UQde5th0h7',
    oktaBaseUrl: 'https://chghealthcare.oktapreview.com',
    oktaMfaGroupName: 'PDE MFA Users',
    providersService: 'https://pde-providers-service-next.dev.platform.aws.chgit.com',
    workdayUrl: 'https://chghealthcare.oktapreview.com/home/workday/0oa1dblhixthtkQMM0h8/15',
  },
  stage: {
    apiUrl: 'https://api.stage.pde.aws.chgit.com',
    cache: 'false',
    calendarSubscriptionMobileUrl: 'https://pde-experience-service.stage.platform.aws.chgit.com',
    calendarSubscriptionUrl: 'webcal://pde-experience-service.stage.platform.aws.chgit.com',
    dataGeneratedUsernamePrefix: 'chgpdeteam+gen-',
    documentsServiceUrl: 'https://pde-documents-service.stage.platform.aws.chgit.com',
    experienceServiceUrl: 'https://pde-experience-service.stage.platform.aws.chgit.com',
    featureFlags: {},
    identityService: 'https://pde-identity-service-next.stage.platform.aws.chgit.com',
    launchDarklyClientSideId: '62cc4a5fa23b3b10d5492e9e',
    logRocket: 'xfgtax/pde-ui-dev',
    oidcClientId: '0oawjd9w93UQde5th0h7',
    oktaBaseUrl: 'https://chghealthcare.oktapreview.com',
    oktaMfaGroupName: 'PDE MFA Users',
    providersService: 'https://pde-providers-service-next.stage.platform.aws.chgit.com',
    workdayUrl: 'https://chghealthcare.oktapreview.com/home/workday/0oa1dblhixthtkQMM0h8/15',
  },
  beta: {
    apiUrl: 'https://api.pde.chghealthcare.com',
    cache: 'false',
    calendarSubscriptionMobileUrl: 'https://pde-experience-service.dev.platform.aws.chgit.com',
    calendarSubscriptionUrl: 'webcal://pde-experience-service.dev.platform.aws.chgit.com',
    dataGeneratedUsernamePrefix: 'chgpdeteam+gen-',
    documentsServiceUrl: 'https://pde-documents-service.prod.platform.aws.chgit.com',
    experienceServiceUrl: 'https://pde-experience-service.prod.platform.aws.chgit.com',
    featureFlags: {},
    identityService: 'https://pde-identity-service-next.prod.platform.aws.chgit.com',
    launchDarklyClientSideId: '62cf0fd31cea781131db088d',
    logRocket: 'xfgtax/pde-ui',
    oidcClientId: '0oa2l7hg06AkwvpDb2p7',
    oktaBaseUrl: 'https://chghealthcare.okta.com',
    oktaMfaGroupName: 'PDE MFA Users',
    providersService: 'https://pde-providers-service-next.prod.platform.aws.chgit.com',
    workdayUrl: 'https://chghealthcare.oktapreview.com/home/workday/0oa1dblhixthtkQMM0h8/15',
  },
  prod: {
    apiUrl: 'https://api.pde.chghealthcare.com',
    cache: 'false',
    calendarSubscriptionMobileUrl: 'https://pde-experience-service.prod.platform.aws.chgit.com',
    calendarSubscriptionUrl: 'webcal://pde-experience-service.prod.platform.aws.chgit.com',
    dataGeneratedUsernamePrefix: 'chgpdeteam+gen-',
    documentsServiceUrl: 'https://pde-documents-service.prod.platform.aws.chgit.com',
    experienceServiceUrl: 'https://pde-experience-service.prod.platform.aws.chgit.com',
    featureFlags: {},
    identityService: 'https://pde-identity-service-next.prod.platform.aws.chgit.com',
    launchDarklyClientSideId: '62cc4c2e9293dc11c3e5fd0f',
    logRocket: 'xfgtax/pde-ui',
    oidcClientId: '0oa2l7hg06AkwvpDb2p7',
    oktaBaseUrl: 'https://chghealthcare.okta.com',
    oktaMfaGroupName: 'PDE MFA Users',
    providersService: 'https://pde-providers-service-next.prod.platform.aws.chgit.com',
    workdayUrl: 'https://chghealthcare.okta.com/home/workday/0oaikf8zxngtznp4i2p7/30',
  },
}
